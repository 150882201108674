import styled from "@emotion/styled";

const Loader = (props) => {
  return <StyledLoader>
    <div className={'animation'}>
      <div className={'dot dot1'} />
      <div className={'dot dot2'} />
      <div className={'dot dot3'} />
      <div className={'dot dot4'} />
    </div>
  </StyledLoader>;
}

export default Loader;

/* style */

const StyledLoader = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #def;

  .animation{
    flex: 1;
    position: relative;

    .dot{
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background: black;
      border-radius: 100%;
      animation: movingdot 2s infinite linear;
      transform: scale(0);

      @keyframes movingdot {
        0% { transform: scale(0) translateX(-75px); }
        50% { transform: scale(1) translateX(0px); }
        99% { transform: scale(0) translateX(75px); }
        100% { transform: scale(0) translateX(-75px); }
      }
    }

    .dot2{
      animation-delay: 0.5s;
    }

    .dot3{
      animation-delay: 1s;
    }

    .dot4{
      animation-delay: 1.5s;
    }
  }
`