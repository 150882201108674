import { lazy } from 'react';

export const routesO = {
  // 'any state' paths
  terms: {
    path: '/termos-de-uso',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Terms')),
    exact: true
  },
  privacy: {
    path: '/politica-de-privacidade',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Privacy')),
    exact: true
  },

  // logged paths
  home: {
    path: '/',
    access: 'logged',
    component: lazy(() => import('../screens/Home/Home')),
    exact: true,
  },
  gameDashboard: {
    path: '/dashboard',
    access: 'logged',
    component: lazy(() => import('../screens/Gamefication/GameDashboard')),
    exact: true,
  },
  pointList: {
    path: '/historico-de-pontos',
    access: 'logged',
    component: lazy(() => import('../screens/Gamefication/PointList')),
    exact: true,
  },
  reward: {
    path: '/premios',
    access: 'logged',
    component: lazy(() => import('../screens/Reward/RewardList')),
    exact: true,
  },
  benefit: {
    path: '/beneficios',
    access: 'logged',
    component: lazy(() => import('../screens/Comp/Comp')),
    exact: true,
  },
  challenge: {
    path: '/desafios',
    access: 'logged',
    component: lazy(() => import('../screens/Challenge/ChallengeList')),
    exact: true,
  },
  news: {
    path: '/noticias',
    access: 'logged',
    component: lazy(() => import('../screens/News/NewsList')),
    exact: true,
  },
  article: {
    path: '/noticias/:articleId',
    access: 'logged',
    component: lazy(() => import('../screens/News/Article')),
    exact: true,
  },
  howToEarn: {
    path: '/como-ganhar',
    access: 'logged',
    component: lazy(() => import('../screens/HowToEarn/HowToEarnList')),
    exact: true,
  },
  recommend: {
    path: '/indique',
    access: 'logged',
    component: lazy(() => import('../screens/Comp/Comp')),
    exact: true,
  },
  download: {
    path: '/baixe',
    access: 'logged',
    component: lazy(() => import('../screens/Comp/Comp')),
    exact: true,
  },
  profile: {
    path: '/meu-cadastro',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
  },
  survey: {
    path: '/pesquisa',
    access: 'logged',
    component: lazy(() => import('../screens/Survey/SurveyList')),
    exact: true,
  },
  surveyRespond: {
    path: '/pesquisa/:surveyId',
    access: 'logged',
    component: lazy(() => import('../screens/Survey/SurveyRespond')),
    exact: true,
  },
  quizRespond: {
    path: '/quiz/:quizId',
    access: 'logged',
    component: lazy(() => import('../screens/Survey/SurveyRespond')),
    exact: true,
  },
  changePassword: {
    path: '/alterar-senha',
    access: 'logged',
    component: lazy(() => import('../screens/Login/ChangePassword')),
    exact: true,
  },
  logout: {
    path: '/logout',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Logout')),
    exact: true,
  },

  // exclusively unlogged paths
  login: {
    path: '/login',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/Login')),
    exact: true,
  },
  resetPassword: {
    path: '/redefinir-senha',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/ForgotPassword')),
    exact: true,
  },
  signUp: {
    path: '/cadastro',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/SignUp')),
    exact: true,
  },
  signUpFull: {
    path: '/cadastro-completo',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/SignUpFull')),
    exact: true,
  },
};

const routes = Object.entries(routesO).map(([key,value]) => value);

export default routes;