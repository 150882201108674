export const HEROKU_REST_URL = '/restHelper';

export const HEROKU_LOYALTY_URL = '/restHelper/persistLoyalty';

export const HEROKU_REDEEM_URL = '/processRedeem';

export const HEROKU_VOUCHER_URL = '/processVoucher';

export const HEROKU_RECEIPT_URL = '/addReceipt';

export const HEROKU_CASE_URL = '/addCase';

export const HEROKU_AUTHORIZATION = 'Bearer 9f9cdb30-a2f6-47d2-8508-fd4f9034f837';
