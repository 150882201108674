const firebaseConfig = {
  apiKey: "AIzaSyDsYAy1c8MdR7r4-Uqt9C8WsJpfi-pqYPw",
  authDomain: "souclamper-dev.firebaseapp.com",
  projectId: "souclamper-dev",
  storageBucket: "souclamper-dev.appspot.com",
  messagingSenderId: "320303213851",
  appId: "1:320303213851:web:813d41e440b7bfc65db7be",
  measurementId: "G-TGMS1M51L8"
};

export default firebaseConfig;