import FormHelper from "../components/utils/FormHelper";

/* configuração para formulário do usuário */

const Userform = (props,startValues) => {
  return {
    Email: FormHelper.useFormInput({
      id: 'email',
      label: props.config.strings.form.label.EMAIL,
      type: 'text',
      inputOptions: {
        disabled: 'disabled',
        spellCheck: false,
      },
      startValue: startValues?.Email,
    }),
    FirstName: FormHelper.useFormInput({
      id: 'firstName',
      label: props.config.strings.form.label.FIRST_NAME,
      type: 'text',
      validations: [{
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_FIRST_NAME,
      }],
      size: {
        desktop: 2,
        tablet: 4,
      },
      inputOptions: {
        spellCheck: false,
      },
      startValue: startValues?.FirstName,
    }),
    LastName: FormHelper.useFormInput({
      id: 'lastName',
      label: props.config.strings.form.label.LAST_NAME,
      type: 'text',
      validations: [{
        type: 'required',
        errorMessage: props.config.strings.form.validation.EMPTY_LAST_NAME,
      }],
      size: {
        desktop: 2,
        tablet: 4,
      },
      inputOptions: {
        spellCheck: false,
      },
      startValue: startValues?.LastName,
    }),
    LoyaltyBirthdate__c: FormHelper.useFormInput({
      id: 'birthdate',
      label: props.config.strings.form.label.BIRTH_DATE,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_BIRTH_DATE,
        },
        {
          type: 'age',
          config: {minAge: 0, maxAge: 130},
          errorMessage: props.config.strings.form.validation.INVALID_DATE,
        },
      ],
      size: {
        desktop: 2,
        tablet: 4,
      },
      inputOptions: {
        spellCheck: false,
        mask: 'date',
        inputMode: 'numeric',
      },
      startValue: startValues?.LoyaltyBirthdate__c,
    }),
    CNPJ_CPF__c: FormHelper.useFormInput({
      id: 'cpf',
      label: props.config.strings.form.label.CPF,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CPF,
        },
        {
          type: 'cpf',
          errorMessage: props.config.strings.form.validation.INVALID_CPF,
        },
      ],
      size: {
        desktop: 2,
        tablet: 4,
      },
      inputOptions: {
        spellCheck: false,
        mask: 'cpf',
        inputMode: 'numeric',
      },
      startValue: startValues?.CNPJ_CPF__c,
    }),
    LoyaltyPhone__c: FormHelper.useFormInput({
      id: 'phone',
      label: props.config.strings.form.label.PHONE,
      type: 'text',
      inputOptions: {
        spellCheck: false,
        mask: 'phone',
        inputMode: 'numeric',
      },
      startValue: startValues?.LoyaltyPhone__c,
    }),
    RefferedCode: FormHelper.useFormInput({
      id: 'promoCode',
      label: props.config.strings.form.label.PROMO_CODE,
      type: props.editProfile ? 'hidden' : 'text',
      validations: [
        {
          type: 'age',
          errorMessage: props.config.strings.form.validation.INVALID_PROMO_CODE,
        },
      ],
      inputOptions: {
        spellCheck: false,
      },
      startValue: startValues?.RefferedCode,
    }),
  }
}

export default Userform;