import { collection, query, onSnapshot, where, orderBy, doc, getDoc, getDocs, limit, startAfter } from 'firebase/firestore';

export default class DataHelper {
  constructor(db){
    this.db = db;
  }

  setUserHelper(userHelper){
    this.userHelper = userHelper;
  }

  getList(snapshot){
    let list = [];

    snapshot.forEach((doc) => {
      list.push(doc.data());
    })

    return list;
  }
  async snapChallenges(callback){
    let challenges = [];
    let userProgresses = [];
    let challengesQ = query(collection(this.db,'/HowToEarn/Progress/records/'));
    let unsub = [];

    unsub['challenges'] = onSnapshot(challengesQ,(snapshot) => {
      challenges = this.getList(snapshot);
      let arrangedChallengeList = this.arrangeChallengeList(challenges,userProgresses);
      callback(arrangedChallengeList);
    })

    // Busca progressos do usuário
    let userFirebaseId = await this.userHelper.getUserFirebaseId();

    let userProgressesQ = query(collection(this.db,'/Users/'+userFirebaseId+'/CampaignProgress/'));
    unsub['user_progresses'] = onSnapshot(userProgressesQ,(snapshot) => {
      userProgresses = this.getList(snapshot);
      let arrangedChallengeList = this.arrangeChallengeList(challenges,userProgresses);
      callback(arrangedChallengeList);
    })

    return unsub;
  }
  arrangeChallengeList(challenges,userProgresses){
    let objectOutput = {};

    challenges.forEach((challenge) => {
      if(!objectOutput[challenge.Id]) objectOutput[challenge.Id] = {};
      objectOutput[challenge.Id] = challenge;
    })

    userProgresses.forEach((userProgress) => {
      if(!objectOutput[userProgress.CampaignItem__c]) objectOutput[userProgress.CampaignItem__c] = {};
      objectOutput[userProgress.CampaignItem__c].userProgress = userProgress;
    })

    let arrayOutput = [];

    Object.entries(objectOutput).forEach((object) => {
      arrayOutput.push(object[1]);
    })

    return arrayOutput;
  }
  snapMilestones(callback){
    let milestonesQ = query(collection(this.db,'Milestone'),where('Season__c', '==', global.season),orderBy('Points__c'));

    let unsub = onSnapshot(milestonesQ,(snapshot) => {
      let milestones = this.getList(snapshot);
      callback(milestones);
    })

    return {milestones:unsub}
  }
  snapHowToEarns(callback){
    let simplePointsQ = query(collection(this.db,'/HowToEarn/SimplePoint/records/'));
    let pointsPerDollarQ = query(collection(this.db,'/HowToEarn/PointPerDollar/records/'));
    let simplePoints = [];
    let pointsPerDollar = [];
    let userData;

    let unsub = {};

    unsub = this.userHelper.snapUser((data) => {
      userData = data;
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints,pointsPerDollar,userData);
      callback(arrangedHowToEarnList);
    });

    unsub['simple_points'] = onSnapshot(simplePointsQ,(snapshot) => {
      simplePoints = this.getList(snapshot);
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints,pointsPerDollar,userData);
      callback(arrangedHowToEarnList);
    })

    unsub['points_per_dollar'] = onSnapshot(pointsPerDollarQ,(snapshot) => {
      pointsPerDollar = this.getList(snapshot);
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints,pointsPerDollar,userData);
      callback(arrangedHowToEarnList);
    })

    return unsub;
  }
  arrangeHowToEarnList(simplePoints,pointsPerDollar,userData){
    let arrayOutput = [];

    let allPoints = [...simplePoints,...pointsPerDollar];

    allPoints.forEach((point) => {
      if(point.HowToEarn__r) {
        const howToEarn = point.HowToEarn__r;

        const loyaltyVisibleString = howToEarn.LoyaltyVisible__c ? howToEarn.LoyaltyVisible__c : '';
        const loyaltyVisibleSet = loyaltyVisibleString.split(';');

        const milestoneVisibleString = howToEarn.MilestoneVisible__c ? howToEarn.MilestoneVisible__c : '';
        const milestoneVisibleSet = milestoneVisibleString.split(';');

        if (
          (loyaltyVisibleString.length === 0 || (userData && loyaltyVisibleSet.includes(userData.LoyaltyCategory__c))) &&
          (milestoneVisibleString.length === 0 || (userData && milestoneVisibleSet.includes(userData.MilestoneCategory__c)))
        ){
          arrayOutput.push(point);
        }
      }
    })

    return arrayOutput;
  }
  snapBanners(callback){
    let bannersQ = query(collection(this.db,'AdvertisingArea'),where('Active__c','==',true));

    let unsub = onSnapshot(bannersQ,(snapshot) => {
      let banners = this.getList(snapshot);
      callback(banners);
    })

    return {banners:unsub}
  }
  snapRewards(callback){
    let rewardsQ = query(collection(this.db,'Reward'),orderBy('Points__c','desc'));

    let unsub = onSnapshot(rewardsQ,(snapshot) => {
      let rewards = this.getList(snapshot);
      callback(rewards);
    })

    return {rewards:unsub}
  }
  snapNews(callback){
    let newsQ = query(collection(this.db,'News'),orderBy('PublishDate__c','desc'));

    let unsub = onSnapshot(newsQ,(snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return {news:unsub}
  }
  snapYTVideos(callback){
    let newsQ = query(collection(this.db,'Youtube'),orderBy('PublishDate__c','desc'));

    let unsub = onSnapshot(newsQ,(snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return {ytvideos:unsub}
  }
  snapTrainings(callback){
    let newsQ = query(collection(this.db,'Training'),orderBy('PublishDate__c','desc'));

    let unsub = onSnapshot(newsQ,(snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return {trainings:unsub}
  }
  async getNews(qty = 10,startAfterDoc = null){
    let newsQ = query(collection(this.db,'News'),orderBy('PublishDate__c','desc'),limit(qty));
    if(startAfterDoc){
      newsQ = query(newsQ,startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if(newsDoc.docs.length){
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data , lastDoc };
  }
  async getYTVideos(qty = 10,startAfterDoc = null){
    let newsQ = query(collection(this.db,'Youtube'),orderBy('PublishDate__c','desc'),limit(qty));
    if(startAfterDoc){
      newsQ = query(newsQ,startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if(newsDoc.docs.length){
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data , lastDoc };
  }
  async getTrainings(qty = 10,startAfterDoc = null){
    let newsQ = query(collection(this.db,'Training'),orderBy('PublishDate__c','desc'),limit(qty));
    if(startAfterDoc){
      newsQ = query(newsQ,startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if(newsDoc.docs.length){
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data , lastDoc };
  }

  async getSurveys(isQuiz,qty = 10,startAfterDoc = null){

    let elQ = query(collection(this.db,isQuiz ? 'Quiz' : 'Survey'),orderBy('Order__c','asc'),limit(qty));
    if(startAfterDoc){
      elQ = query(elQ,startAfter(startAfterDoc));
    }
    let elDoc = await getDocs(elQ);

    let data = [];
    let lastDoc = null;

    if(elDoc.docs.length){
      data = elDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data , lastDoc };
  }

  async getArticle(articleId){
    let firebaseId = articleId.substr(0,15);
    let articleQ = doc(this.db,'News',firebaseId);
    let articleDoc = await getDoc(articleQ);

    if(articleDoc?.exists()){
      return articleDoc.data();
    }

    return false;
  }

  async getSurvey(surveyId,category = 'Survey'){
    if(!surveyId) return false;

    let firebaseId = surveyId.substr(0,15);
    let surveyQ = doc(this.db,category,firebaseId);
    let surveyDoc = await getDoc(surveyQ);

    if(surveyDoc?.exists()){
      return surveyDoc.data();
    }

    return false;
  }

  async getSurveyQuestions(surveyId,category = 'Survey'){
    if(!surveyId) return false;

    let firebaseId = surveyId.substr(0,15);
    let questionsQ = query(collection(this.db,'/'+category+'/'+firebaseId+'/Question/'),orderBy('Order__c'));
    let questionsDoc = await getDocs(questionsQ);
    let data = [];

    if(questionsDoc.docs.length){
      data = questionsDoc.docs.map((doc) => {
        return doc.data();
      })
    }

    return data;
  }

  async getPrivacyText(callback){
    let privacyQ = doc(this.db,'AppSetting','PrivacyPolicy');
    let privacyDoc = await getDoc(privacyQ);

    if(privacyDoc?.exists() && privacyDoc?.data()['HtmlValue__c']){
      callback(privacyDoc.data()['HtmlValue__c']);
    }
    else{
      callback('');
    }
  }

  async getTermsText(callback){
    let termsQ = doc(this.db,'AppSetting','TermsOfService');
    let termsDoc = await getDoc(termsQ);

    if(termsDoc?.exists() && termsDoc?.data()['HtmlValue__c']){
      callback(termsDoc.data()['HtmlValue__c']);
    }
    else{
      callback('');
    }
  }

  async getHerokuUrl(path){
    let urlQ = doc(this.db,'AppSetting','HerokuUrl');
    let urlDoc = await getDoc(urlQ);

    let url = '';

    if(urlDoc.exists() && urlDoc.data().TextValue__c){
      url = urlDoc.data().TextValue__c + path;
    }

    return url;
  }

  async loadCurrentSeasonId(){
    let seasonQ = doc(this.db,'AppSetting','CurrentSeason');
    let seasonDoc = await getDoc(seasonQ);

    let season = '';

    if(seasonDoc.exists() && seasonDoc.data().TextValue__c){
      season = JSON.parse(seasonDoc.data().TextValue__c)?.Id;
    }

    global.season = season;

    return season;
  }

  async getRewardFilters(){
    let filtersQ = doc(this.db,'AppSetting','FilterProductOptions');
    let filtersDoc = await getDoc(filtersQ);

    let filters = [];

    if(filtersDoc?.exists() && filtersDoc?.data()['TextValue__c']){
      filters = JSON.parse(filtersDoc.data()['TextValue__c']);
    }

    return filters;
  }

  dateDisplayFormat(date){
    if(date.search('-') !== -1){
      return date.split('-').reverse().join('/')
    }

    return date;
  }

  appScreenToUrl(routes,appScreen){
    switch(appScreen){
      case 'Profile':
        return routes.profile.path;
      case 'Survey':
        return routes.survey.path;
      case 'ReceiptHistory':
        return '/';
      default:
        return '/';
    }
  }

  nl2br(str, is_xhtml){
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  docsToArray(elDocs){
    let data = [];

    if(elDocs.docs.length){
      data = elDocs.docs.map((doc) => {
        return doc.data();
      })
    }

    return data;
  }
}
