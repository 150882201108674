import styled from '@emotion/styled';
import { useState, useEffect, useRef } from 'react';
import userPH from '../assets/profile1v4.png';

const Avatar = (props) => {
  const [ image, setImage ] = useState(null)
  const inputRef = useRef(null);

  useEffect(() => {
    if(props.photoURL !== undefined){
      setImage(props.photoURL || userPH);
    }
    else{
      setImage(props.data?.user?.photoURL || userPH);
    }
  },[props.data,props.photoURL])

  const onImageChange = async (ev) => {
    if (ev.target.files && ev.target.files[0]) {
      let img = ev.target.files[0]; // <-- upload
      setImage(URL.createObjectURL(img));
      props.userHelper.uploadAvatar(img);
    }    
  }

  const avatarClick = (ev) => {
    ev.stopPropagation();
    inputRef.current.click();
  }
 
  return <StyledAvatar config={props.config} image={image} isClickable={!!props.onClick} onClick={avatarClick}>
    <div className={'header-user-icon'} >
      {props.showIcon && <i className={'fa fa-camera indicator-icon'} />}
    </div>
    <input ref={inputRef} className={'avatar-input'} type="file" name="myImage" onChange={onImageChange}/>
  </StyledAvatar>
}

export default Avatar;

const StyledAvatar = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 100%;

  &:hover{
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5);
    opacity: 0.8;
  }

  .header-user-icon{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: relative;
    background-image: url('${props => props.image}');
    background-size: cover;
    background-position: center;
    opacity: 1;
    cursor: ${props => props.onClick ? 'pointer' : 'initial'};

    .indicator-icon{
      position: absolute;
      border-radius: 100%;
      bottom: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background: white;
      font-weight: bold;
      font-size: 1em;
      line-height: 30px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
      text-align: center;
      z-index: 2;
      background: ${props => props.config?.themeLayout?.background};
      color: ${props => props.config?.themeLayout?.title};
    }
  }

  .avatar-input{
    position: absolute;
    bottom: -30px;
    opacity: 0;
    width: 0;
    height: 0;
  }
`