import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { Router, useHistory, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Header from './components/Header/Header.js';
import UserHelper from './components/utils/UserHelper';
import DataHelper from './components/utils/DataHelper';
import ToolHelper from './components/utils/ToolHelper.js';
import Loader from './components/Molecules/Loader.js';
import Popup from './components/Molecules/Popup.js';

import DeclareRoutes from './components/routes/DeclareRoutes.js';

import firebaseConfig from './config/credentials/firebase';
import STRINGS from './config/strings';
import LAYOUT from './config/layout/general';
import PRESETS from './config/layout/presets';
import THEME from './config/layout/theme';
import COLORS from './config/layout/colors';
import routes, { routesO } from './config/routes'; // Route list
import Userform from './config/Userform'; // Route list

import logo from './assets/cogny_light.png';
import placeholder from './assets/placeholder.png';

let app = initializeApp(firebaseConfig);
let db = getFirestore(app);

const headerData = {
  apptitle: 'Sou+Clamper',
  logo: logo,
  links: [
    { url: routesO.reward.path, label: 'Prêmios' },
    { url: routesO.gameDashboard.path, label: 'Benefícios' },
    { url: routesO.challenge.path, label: 'Desafios' },
    { url: routesO.howToEarn.path, label: 'Aceleradores' },
    { url: routesO.news.path, label: 'Notícias' },
  ],
  bgColor: 'white',
  headerUser: {
    isMenu: true,
    menuItems: [
      { url: '/notificacoes', label: 'Notificações' },
      { url: routesO.profile.path, label: 'Meu Cadastro' },
      { url: '/alterar-senha', label: 'Alterar Senha' },
      { url: '/meus-premios', label: 'Meus Prêmios' },
      { url: routesO.pointList.path, label: 'Histórico de Pontos' },
      { url: routesO.recommend.path, label: 'Indique e Ganhe' },
      { url: routesO.terms.path, label: 'Termos de Uso', target: '_blank' },
      { url: routesO.privacy.path, label: 'Política de Privacidade', target: '_blank' },
      { url: routesO.logout.path, label: 'Sair' },
    ]
  },
}

function App() {
  let userHelper = useMemo(() => new UserHelper(db),[]);
  let dataHelper = useMemo(() => new DataHelper(db),[]);
  userHelper.setDataHelper(dataHelper);
  dataHelper.setUserHelper(userHelper);

  const history = useHistory();
  const location = useLocation();

  const [milestones,setMilestones] = useState([]);
  const [challenges,setChallenges] = useState([]);
  const [howToEarns,setHowToEarns] = useState([]);
  const [rewards,setRewards] = useState([]);
  const [news,setNews] = useState([]);
  const [banners,setBanners] = useState([]);
  const [trainings,setTrainings] = useState([]);
  const [ytVideos,setYTVideos] = useState([]);
  const [unsubFunctions,setUnsubFunctions] = useState({});
  const [user,setUser] = useState({});
  const [hasAuthResponse,setHasAuthResponse] = useState(false);
  const [screenProps,setScreenProps] = useState({});
  const [isLoadedSnapshots,setIsLoadedSnapshots] = useState(false);
  const [doLoadSnapshots,setDoLoadSnapshots] = useState(false);
  const [showHeader,setShowHeader] = useState(false);

  const config = {
    layout: LAYOUT,
    strings: STRINGS,
    presets: PRESETS,
    colors: COLORS,
    theme: THEME,
    logo,
    placeholder,
    Userform,
  }
  
  const unsubscribe = async () => {
    if(unsubFunctions && Object.keys(unsubFunctions).length > 0){
      let unsubArray = Object.entries(unsubFunctions);

      unsubArray.forEach((unsub) => {
        if(unsub[1] && typeof unsub[1] === 'function'){
          unsub[1]();
        }
      });

      setDoLoadSnapshots(false);
      setIsLoadedSnapshots(false);
      setUnsubFunctions({});
    }
  }

  useEffect(() => {
    changeScreenProps();
  },[user,challenges,milestones,howToEarns,banners,rewards,news,trainings,ytVideos])

  useEffect(() => {
    startAuthObserver();
  },[location])

  useEffect(() => {
    setHasAuthResponse(false);
    startAuthObserver();

    return () => unsubscribe();
  },[])

  useEffect(() => {
    if(!isLoadedSnapshots && doLoadSnapshots){
      loadSnapshots();
    }
  },[doLoadSnapshots])

  const startAuthObserver = async () => {
    const authState = onAuthStateChanged(getAuth(),async () => {
      let userRef = await userHelper.getUserFirebaseId();
      
      if(userRef){
        global.isAuthenticated = true;
        await userHelper.setLastTimeOnline();

        if(!isLoadedSnapshots){
          setDoLoadSnapshots(true);
        }
      }
      else{
        global.isAuthenticated = false;
      }

      setHasAuthResponse(true);
    })
  
    setUnsubFunctions(us => { us['authState' + new Date().getTime()] = authState; return us; });
  }

  const loadSnapshots = async () => {
    let unsubT = {};

    await dataHelper.loadCurrentSeasonId();

    unsubT = { ...unsubT, ...await userHelper.snapUser((data) => {
      setUser(data);
    })};

    unsubT = { ...unsubT, ...dataHelper.snapMilestones((list) => {
      setMilestones(list);
    })};

    unsubT = { ...unsubT, ...await dataHelper.snapChallenges((list) => {
      setChallenges(list);
    })};

    unsubT = { ...unsubT, ...dataHelper.snapHowToEarns((list) => {
      setHowToEarns(list);
    })};

    unsubT = { ...unsubT, ...dataHelper.snapBanners((list) => {
      setBanners(list);
    })};

    unsubT = { ...unsubT, ...dataHelper.snapRewards((list) => {
      setRewards(list);
    })};

    unsubT = { ...unsubT, ...dataHelper.snapNews((list) => {
      setNews(list);
    })};

    unsubT = { ...unsubT, ...dataHelper.snapYTVideos((list) => {
      setYTVideos(list);
    })};

    unsubT = { ...unsubT, ...dataHelper.snapTrainings((list) => {
      setTrainings(list);
    })};

    setIsLoadedSnapshots(true);
    setDoLoadSnapshots(false);
    setUnsubFunctions(us => ({ ...us, ...unsubT }));
  }

  const popup = ToolHelper.usePopup();

  const changeScreenProps = () => {
    setScreenProps({
      setShowHeader,
      userHelper,
      dataHelper,
      history,
      unsubscribe,
      routes: routesO,
      data: {
        user,
        milestones,
        challenges,
        howToEarns,
        banners,
        rewards,
        news,
        trainings,
        ytVideos,
      },
      config,
      popup,
    })
  }

  return (
    !hasAuthResponse ? <Loader/> :
    <Router history={history}>
      <Suspense fallback={<Loader/>}>
        <div style={{display:'flex',flexDirection:'column',minHeight:'100vh',fontFamily:config?.layout?.FONT_FAMILY,fontSize:config?.layout?.FONT_BASE_SIZE}}>
          <Header data={{user,showHeader,...headerData}} config={config} history={history} userHelper={userHelper}/>
          <DeclareRoutes screenProps={screenProps} routes={routes} />
          <Popup control={popup}/>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;