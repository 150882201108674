import React, { useMemo } from 'react';
import ToolHelper from "../utils/ToolHelper";
import HeaderDropMenu from './HeaderDropMenu';
import Avatar from '../Molecules/Avatar';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts'

function HeaderUser(props) {
  const { data, isOpen, userHelper } = props;

  const config = useMemo(() => ToolHelper.getThemedConfig(props,'Header.preset'),[props.config]);

  return (
    <StyledHeaderUser config={config} isOpen={isOpen} onClick={() => props.toggleIsOpen()}>
      <div className={'header-user-block'}>
        <div className={'header-user-icon'}>
          <Avatar data={data} config={config} userHelper={userHelper}/>
        </div>
        <div className={'header-user-label-group'}>
          <div className={'header-user-name'}>{data?.user?.FirstName?.substring(0,15) || 'Visitante'}</div>
          <div className={'header-user-points'}>{data?.user?.CurrentContactSeason__r?.TotalRedeemableAvailable__c || 0} {config?.strings?.POINTS}</div>
        </div>
        <div className={'header-user-arrow-container'}>
          <span className={'header-user-arrow'}>.</span>
        </div>
      </div>
      <HeaderDropMenu menuItems={data?.headerUser?.menuItems} isOpen={isOpen} data={data} config={config} />
    </StyledHeaderUser>
  );
}

export default HeaderUser;

/* style */

const StyledHeaderUser = styled.div`
  height: 100%;
  padding: 0 10px;
  color: ${props => props.config?.themeLayout?.link};
  cursor: pointer;
  user-select: none;
  background: ${props => props.isOpen ? 'rgba(0,0,0,0.1)' : 'none'};

  @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    position: relative
  }

  .header-user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    height: 100%;

    .header-user-icon {
      width: 35px;
      height: 35px;
    }

    .header-user-label-group {
      flex: 1;
      margin-left: 10px;

      .header-user-name {
        font-size: 0.85em;
        text-transform: uppercase;
      }

      .header-user-points {
        font-weight: bold;
      }
    }

    .header-user-arrow-container {
      display: flex;
      margin-left: 15px;
      justify-content: center;
      align-items: center;

      .header-user-arrow {
        font-family: ${props => props.config?.layout?.FONT_ICON};
        font-size: 1.2em;
        transition: transform 200ms linear;
        transform: rotateZ(${props => props.isOpen ? 0 : "-90deg"});
      }
    }
  }

`