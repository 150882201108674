import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import HeaderUser from './HeaderUser.js';
import HeaderMobile from './HeaderMobile.js';
import ToolHelper from "../utils/ToolHelper";
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts'

function HeaderBase(props) {
  const [isMenuUserOpen,setIsMenuUserOpen] = useState(false);
  const [isMenuMobileOpen,setIsMenuMobileOpen] = useState(false);

  const { data, userHelper } = props;

  const config = useMemo(() => ToolHelper.getThemedConfig(props,'Header.preset'),[props.config]);
  
  const hideMenuUser = () => {
    setIsMenuUserOpen(false);
  };
  
  const toggleMenuUser = () => {
    if(!isMenuUserOpen){
      hideMenuMobile();
    }
    setIsMenuUserOpen(!isMenuUserOpen);
  };

  const hideMenuMobile = () => {
    setIsMenuMobileOpen(false);
  };

  const toggleMenuMobile = () => {
    if(!isMenuMobileOpen){
      hideMenuUser();
    }
    setIsMenuMobileOpen(!isMenuMobileOpen);
  };

  return (
    <StyledHeader showHeader={props.data.showHeader} config={config} data={props.data}>
      <div className={'header-line'}>
        <Link to={data?.homeUrl || '/'} className={'link-logo'}>
          <div className={'logo'} />
        </Link>
        <div className={'list-links'}>
          {data?.links?.map((link,index) => {
            return <div className={'header-link'} key={index}>
              <Link className={'menu-link'} to={link.url} target={link.target}>{link.label}</Link>
            </div>
          })}
        </div>
        <div className={'side-container'}>
          <HeaderMobile isOpen={isMenuMobileOpen} toggleIsOpen={toggleMenuMobile} data={data} config={config}/>
          <HeaderUser isOpen={isMenuUserOpen} toggleIsOpen={toggleMenuUser} data={data} config={config} userHelper={userHelper}/>
        </div>
      </div>
    </StyledHeader>
  );
}

const Header = withRouter(HeaderBase)

export default Header;

/* style */

const StyledHeader = styled.div`
  display: ${props => props.showHeader ? 'block' : 'none'};
  background: ${props => props.config?.themeLayout?.background || 'transparent'};
  position: sticky;
  top: 0;
  z-index: 9999;

  .header-line {
    display: flex;
    flex-direction: row;
    max-width: ${props => props.config?.layout?.CONTAINER_SIZE + 'px' || 'none'};
    margin: auto;
    padding-left: ${props => props.config?.layout?.GUTTER || 20}px;
    padding-right: ${props => props.config?.layout?.GUTTER || 20}px;

    .link-logo {
      font-size: 0;

      .logo {
        display: inline-block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: clamp(100px,calc(15vw),150px);
        height: clamp(60px,calc(7vw),80px);
        background-image: url(${props => props.data?.logo});
      }
    }

    .list-links {
      flex: 1;
      flex-direction: row;
      margin-left: ${props => props.config?.layout?.GUTTER * 2}px;
      align-self: center;
      
      @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
        display: none;
      }

      .header-link {
        display: inline-block;
        margin: 0 1.5vw;

        .menu-link {
          font-size: 0.8em;
          text-decoration: none;
          text-transform: uppercase;
          color: ${props => props.config?.themeLayout?.link};

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .side-container {
      display: flex;
      flex-direction: row;
      margin-left: auto;
    }
  }
`