const LAYOUT = {
  FONT_FAMILY: 'Nunito',
  // FONT_FAMILY: 'Signika Negative',
  FONT_ICON: 'Font-Icon',
  FONT_BASE_SIZE: 'clamp(13px,calc(.8rem + .25vw),20px)',

  CONTAINER_SIZE: 1260,
  ARTICLE_SIZE: 888,
  GUTTER: 20,
  
  MAIN_COLOR: '#555',
  MAIN_COLOR_2: '#555',
  MAIN_COLOR_3: '#666',
  INVERSE_COLOR: 'white',
  HEADER_COLOR: 'white',
  BOX_COLOR: '#555',

  INPUT_COLOR: '#419BE0',
  BUTTON_COLOR: '#419BE0',
  ASIDE_BUTTON_COLOR: '#419BE0',
  INACTIVE_COLOR: '#cccccc',
  
  BOX_BG: '#eee',
  DARK_BG: '#E4A00E',
  CONTENT_BG: 'white',
  HEADER_BG: 'black',
  LOGIN_BG: 'linear-gradient(45deg, #419BE0, #5F45A6)',
  
  BOX_BORDER_TOP_LEFT_RADIUS:     5,
  BOX_BORDER_TOP_RIGHT_RADIUS:    5,
  BOX_BORDER_BOTTOM_LEFT_RADIUS:  5,
  BOX_BORDER_BOTTOM_RIGHT_RADIUS: 5,
}

export default LAYOUT;