import styled from "@emotion/styled";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import ToolHelper from "../utils/ToolHelper";

const HeaderDropMenuItem = (props) => {
  const { config } = props; 

  return <StyledHeaderDropMenuItem config={config}>
    <Link to={props.url} config={config} target={props.target}>{props.label}</Link>
  </StyledHeaderDropMenuItem>;
}

const HeaderDropMenu = (props) => {

  let config = useMemo(() => ToolHelper.getThemedConfig(props,'HeaderDropMenu.preset'),[props.config]);

  return <StyledHeaderDropMenu config={config} isOpen={props.isOpen}>
    <ul>
      {props.menuItems?.map(menuItem => 
        <HeaderDropMenuItem key={menuItem.label} label={menuItem.label} target={menuItem.target} url={menuItem.url} data={props.data} config={config} />
      )}
    </ul>
  </StyledHeaderDropMenu>
}

export default HeaderDropMenu;

/* style */

const StyledHeaderDropMenu = styled.div`
  position: absolute;
  background: ${props => props.config?.themeLayout?.background || 'transparent'};
  top: clamp(60px,calc(7vw),80px);
  right: 0;
  padding: 20px;
  border-bottom-left-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_LEFT_RADIUS || 0) * 2}px;
  border-bottom-right-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_RIGHT_RADIUS || 0) * 2}px;
  border-bottom: 4px solid rgba(0,0,0,0.2);
  z-index: 9999;
  overflow: hidden;
  transition: transform 200ms linear;
  transform-origin: top;
  transform: scaleY(${props => props.isOpen ? 1 : 0});
`;

const StyledHeaderDropMenuItem = styled.li`
  width: 200px;
  padding-right: 20px;

  a {
    display: block;
    padding: 0.7em 0;
    font-size: 0.8em;
    text-transform: uppercase;
    color: ${props => props.config?.themeLayout?.link};

    &:hover {
      opacity: 0.7;
    }
  }
`
