const COLORS = {
  LIGHT: {
    A: '#FFF',
    B: '#FFEDBB',
    C: '#FFAAFF',
    D: '#FFEDFF',
    BG: {
      A: '#FFF',
      B: '#FD0',
      C: '#EEE',
      D: 'url("https://setecnet.com.br/home/wp-content/uploads/2018/02/betfair-pattern.png") bottom #FFF',
    }
  },
  DARK: {
    A: '#555',
    B: '#389',
    C: '#767676',
    D: '#CCC',
    E: '#AAA',
    SUCCESS: '#33691E',
    ERROR: '#DD2C00',
    BG: {
      A: '#555',
      B: 'url("https://setecnet.com.br/home/wp-content/uploads/2018/02/betfair-pattern.png") #389',
      C: '#777',
      D: '#1c343c',
      E: '#000',
    }
  },
  MISC: {
    TRANSPARENT: 'transparent',
    IMAGE_BG: 'url("data:image/webp;base64,UklGRmwAAABXRUJQVlA4WAoAAAAQAAAABAAABAAAQUxQSBYAAAABDzAhERFCadtIbAwea/+oRfQ/GvwBVlA4IDAAAACQAQCdASoFAAUAAgA0JaQAApplHeAA/tPpPS2F/PjfDX7Mr9vZ2Xy98v/pAAAAAAA=")',
  }
}

export default COLORS;