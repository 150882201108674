import { Suspense } from 'react';
import { Switch } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const DeclareRoutes = ({routes,screenProps}) => (
  <Switch>
    <Suspense>
      {routes.map(({ component: Component, path, exact, access = 'public' }) => {
        let config = {
          path,
          key: path,
          exact,
        };

        switch(access){
          case 'unlogged':
            return <PublicRoute {...config} onlyUnlogged>
              <Component {...screenProps}/>
            </PublicRoute>

          case 'logged':
            return <PrivateRoute {...config}>
              <Component {...screenProps}/>
            </PrivateRoute>

          case 'public':
          default:
            return <PublicRoute {...config}>
              <Component {...screenProps}/>
            </PublicRoute>
        }
      }
     )}
    </Suspense>
  </Switch>
);

export default DeclareRoutes;
