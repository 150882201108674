import { routesO } from './routes';

const STRINGS = {

  // ---------------- General -------------------------------------------------------------------------

  POINT: 'KM',
  POINTS: 'KMs',
  
  LEVEL: 'nível',
  LEVELS: 'níveis',

  BACK: 'Voltar',
  LOAD_MORE: 'Carregar mais',

  // ---------------- Form ----------------------------------------------------------------------------

  form: {
    label: {
      EMAIL: 'E-mail',
      FIRST_NAME: 'Nome',
      LAST_NAME: 'Sobrenome',
      PASSWORD: 'Senha',
      CONFIRM_PASSWORD: 'Confirmar senha',
      CURRENT_PASSWORD: 'Senha atual',
      NEW_PASSWORD: 'Nova senha',
      NEW_CONFIRM_PASSWORD: 'Confirme sua nova senha',
      BIRTH_DATE: 'Data de nascimento',
      CPF: 'CPF',
      PHONE: 'Telefone (opcional)',
      PROMO_CODE: 'Código do amigo ou promocional',
      TERMS_AND_PRIVACY: `Li e aceito os <a href="${routesO.terms.path}" target="_blank">Termos e Condições</a> e a <a href="${routesO.privacy.path}" target="_blank">Política de Privacidade de Dados</a>.`,
      SEND: 'Enviar',
    },

    message: {
      GENERIC_ERROR: 'Não foi possível enviar sua requisição. Por favor, tente novamente mais tarde.',
      USER_NOT_FOUND: 'Usuário não encontrado',
      NO_UID: 'Não foi possível identificar o usuário. Por favor, recarregue a página e tente novamente.',
      USER_CANT_LOGIN: 'Não foi possível logar. Por favor, tente novamente mais tarde.',
      FORGOT_PASSWORD_SUCCESS: '&#10003; Enviamos um e-mail com instruções para redefinir sua senha.',
      DATA_IS_SAVED: '&#10003; Seus dados foram salvos.',
      PASSWORD_CHANGE_SUCCESS: '&#10003; Sua senha foi alterada.',
    },
    
    validation: {
      EMPTY_EMAIL: 'Digite o endereço de e-mail',
      INVALID_EMAIL: 'Digite um endereço de e-mail válido',
      USED_EMAIL: 'Este e-mail já está em uso',
      EMPTY_PASSWORD: 'Digite sua senha',
      NEW_EMPTY_PASSWORD: 'Digite sua nova senha',
      EMPTY_CONFIRM_PASSWORD: 'Digite a confirmação da senha',
      IDENTICAL_PASSWORD: 'A senha e confirmação devem ser idênticas',
      INVALID_PASSWORD: 'Senha inválida',
      WEAK_PASSWORD: 'Senha precisa ter pelo menos 6 caracteres',
      EMPTY_TERMS_CHECKBOX: 'Marque a caixa de seleção para continuar',
      EMPTY_CPF: 'Digite seu CPF',
      INVALID_CPF: 'Digite um CPF válido',
      USED_CPF: 'Este CPF já está cadastrado',
      EMPTY_BIRTH_DATE: 'Digite a data de nascimento',
      INVALID_DATE: 'Digite uma data válida',
      EMPTY_LAST_NAME: 'Digite seu sobrenome',
      EMPTY_FIRST_NAME: 'Digite seu nome',
      INVALID_PROMO_CODE: 'Código promocional inválido',
    },
  },

  // ---------------- Components ----------------------------------------------------------------------

  components: {
    socialLogin: {
      OR: 'OU',
      ACCESS_USING: 'Acesse usando',
    },
    challenge: {
      SECTION_TITLE: 'Complete os desafios para pontuar',
      SECTION_LINK: 'VER TODOS OS DESAFIOS',
      LIST_TITLE: 'Desafios',
      LIST_DESCRIPTION: 'Complete os desafios para ganhar pontos!',
      LIST_BUTTON: 'Ver detalhes',
      LIST_BANNER: 'Os pontos não acabam aqui. Conheça também os <b>ACELERADORES</b>!',
    },
    howToEarn: {
      SECTION_TITLE: 'Saiba como acumular pontos',
      SECTION_LINK: 'VER TODOS OS ACELERADORES',
      LIST_TITLE: 'Aceleradores',
      LIST_BANNER: 'Os pontos não acabam aqui. Conheça também os <b>DESAFIOS</b>!',
    },
    filter: {
      TITLE: 'Filtros',
      APPLY: 'Aplicar',
    },
    reward: {
      LIST_TITLE: 'Prêmios',
      LIST_DESCRIPTION: 'Troque seus pontos por prêmios',
      LIST_BANNER: 'Quer ganhar mais pontos? Conheça os <b>ACELERADORES</b>!',
      NO_RESULTS_TITLE: 'Nenhum resultado encontrado :(',
      NO_RESULTS_MESSAGE: 'Por favor, altere os filtros e tente novamente',
      SECTION_TITLE: 'Troque seus pontos por prêmios',
      SECTION_LINK: 'VER TODOS OS PRÊMIOS',
      REWARD_CONFIRMATION_MESSAGE: 'Deseja realmente resgatar <b>{QTY}x {REWARD_NAME}</b> por <b>{REWARD_TOTAL_POINTS} {POINTS_LABEL}</b>?',
      REDEEM_BUTTON: 'Resgatar',
      QUANTITY: 'Quantidade:',
      TYPE: 'Tipo:',
      BALANCE: 'Saldo:',
      SUCCESS_MESSAGE: '<b>Prêmio resgatado!</b><br/>Em breve, você receberá um e-mail com mais instruções para efetivar o resgate.',
      ERROR_MESSAGE: '<b>Não foi possível fazer o resgate.</b><br/>Por favor, tente novamente mais tarde.',
      LOADING_TEXT: 'Processando',
      MILESTONE_REWARD_LIST: 'Lista de prêmios',
    },
    news: {
      LIST_TITLE: 'Notícias',
      SECTION_TITLE: 'Notícias',
      SECTION_LINK: 'VER MAIS NOTÍCIAS',
      CATEGORY_LABELS: {
        Youtube: 'Vídeos',
        News: 'Eventos',
        Training: 'Treinamentos',
      },
    },
    points: {
      CATEGORY_LABELS: {
        Statement: 'Extrato',
        DueDate: 'Pontos à vencer',
      },
    },
    game: {
      ABOVE_CATEGORY_TEXT: '{LEVEL_LABEL} Atual',
      BOX_POINTS_TEXT: '{POINTS_LABEL} disponíveis',
      BOX_MOBILE_POINTS_TEXT: 'Você já conquistou <b>{CURRENT_POINTS}</b> {POINTS_LABEL}',
      NEXT_LEVEL: 'Ganhe <b>{POINTS_TO_LEVEL}</b> {POINTS_LABEL} para alcançar o {LEVEL_LABEL} {LEVEL}',
      NEXT_LEVEL_2: 'Faltam <b>{POINTS_TO_LEVEL}</b> {POINTS_LABEL} para alcançar este {LEVEL_LABEL}',
      AVAILABLE_REWARD: '1 prêmio disponível!',
      AVAILABLE_REWARDS: '{QTY} prêmios disponíveis!',
      SHOW_REWARDS: '<i class="fa fa-trophy"></i> Conheça os prêmios',
      YOUR_LEVEL: 'Seu nível',
      YOUR_PROGRESS: 'Seu progresso',
      CURRENT_BENEFITS: 'Benefícios atuais',
      OTHER_BENEFITS: 'Benefícios {LEVEL}',
      REACHED_LEVEL: 'Você alcançou este nível!',
    },
    survey: {
      list: {
        survey: {
          TITLE: 'Pesquisa',
          NO_RESULTS_TITLE: 'Todas as pesquisas respondidas!',
          NO_RESULTS_MESSAGE: 'Volte mais tarde para mais pesquisas',
        },
        quiz: {
          TITLE: 'Quiz',
          NO_RESULTS_TITLE: 'Todos os quizzes respondidos!',
          NO_RESULTS_MESSAGE: 'Volte mais tarde para mais quizzes',
        },
      },
      play: {
        TIP_MAX_OPTIONS: 'Escolha até {VALUE} opções.',
        TIP_MIN_NUMBER: 'Valor mínimo: {VALUE}.',
        TIP_MAX_NUMBER: 'Valor máximo: {VALUE}.',
        SEND: 'Enviar &nbsp; <i class="fa fa-chevron-right" />',
        SENDING: 'Enviando...',
        SKIP: 'Pular &nbsp; <i class="fa fa-child" />',
        NEXT_QUESTION: 'Próxima pergunta',
        END_OF_QUIZ: 'Fim do quiz',
        TIMER: 'em {VALUE}s...',
        CORRECT: 'Certa resposta!',
        WRONG: 'Que pena, você errou!',
      }
    }
  },

  // ---------------- Screens -------------------------------------------------------------------------

  screen: {
    forgotPassword: {
      TITLE: 'Recuperar Senha',
      BACK_TO_LOGIN: 'Voltar para login',
    },
  
    login: {
      TITLE: 'Fazer Login',
      FORGOT_PASSWORD_LINK: 'Esqueceu a senha?',
      SIGN_UP_LINK: 'Criar conta',
    },

    signUp: {
      TITLE: 'Criar Conta',
      BACK_TO_LOGIN: 'Voltar para login',
      SEND_TO_SIGNUP_FULL: 'Próxima',
    },

    signUpFull: {
      TITLE: 'Completar Cadastro',
    },

    profile: {
      TITLE: 'Editar Perfil',
    },

    changePassword: {
      TITLE: 'Alterar Senha',
    },
  },
}

export default STRINGS;