import COLORS from "./colors";

const PRESETS = {

  // ------------- system ----------------------------
  // Custom presets will inherit default's definitions
  default: {
    isBGLight: true,
    background: COLORS.LIGHT.BG.A,
    title: COLORS.DARK.A,
    text: COLORS.DARK.A,
    subtext: COLORS.DARK.C,
    link: COLORS.DARK.B,
    inactive: COLORS.DARK.D,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    secButtonBackground: COLORS.TRANSPARENT,
    secButtonText: COLORS.DARK.C,
    label: COLORS.DARK.B,
    error: COLORS.DARK.ERROR,
    success: COLORS.DARK.SUCCESS,
  },
  // -------------------------------------------------

  colorful: {
    isBGLight: false,
    background: COLORS.DARK.BG.B,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.B,
    subtext: COLORS.LIGHT.D,
    link: COLORS.LIGHT.C,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.LIGHT.BG.B,
    buttonText: COLORS.DARK.B,
    buttonSecondaryBackground: COLORS.MISC.TRANSPARENT,
    buttonSecondaryText: COLORS.LIGHT.B,
    buttonInactiveBackground: COLORS.LIGHT.BG.C,
    buttonInactiveText: COLORS.DARK.C,
    label: COLORS.LIGHT.C,
  },
  box: {
    isBGLight: true,
    background: COLORS.LIGHT.BG.C,
    title: COLORS.DARK.A,
    text: COLORS.DARK.A,
    subtext: COLORS.DARK.E,
    link: COLORS.DARK.B,
    inactive: COLORS.DARK.E,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    buttonSecondaryBackground: COLORS.MISC.TRANSPARENT,
    buttonSecondaryText: COLORS.DARK.B,
    buttonInactiveBackground: COLORS.DARK.D,
    buttonInactiveText: COLORS.LIGHT.A,
  },
  header: {
    isBGLight: false,
    background: COLORS.DARK.BG.D,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    link: COLORS.LIGHT.A,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.LIGHT.BG.B,
    buttonText: COLORS.DARK.A,
  },
  form: {
    isBGLight: true,
    background: COLORS.LIGHT.BG.A,
    title: COLORS.DARK.A,
    text: COLORS.DARK.C,
    subtext: COLORS.DARK.D,
    link: COLORS.DARK.A,
    inactive: COLORS.DARK.D,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    label: COLORS.DARK.C,
  },
  listHeader: {
    isBGLight: false,
    background: COLORS.DARK.BG.E,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    link: COLORS.LIGHT.A,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.LIGHT.BG.B,
    buttonText: COLORS.DARK.A,
  },
  listBanner: {
    isBGLight: false,
    background: COLORS.DARK.BG.E,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    link: COLORS.LIGHT.B,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.LIGHT.BG.B,
    buttonText: COLORS.DARK.A,
  },
  funkyDefault: {
    background: COLORS.LIGHT.BG.D,
  }
}

export default PRESETS;