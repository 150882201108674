import { useMemo } from "react";
import styled from "@emotion/styled";

import ToolHelper from "../utils/ToolHelper";
import HamIcon from '../Molecules/HamIcon.js';
import HeaderDropMenu from './HeaderDropMenu';
import { BREAKPOINTS } from '../configs/consts'

function HeaderMobile(props) {

  const { toggleIsOpen, isOpen, data } = props;

  const config = useMemo(() => ToolHelper.getThemedConfig(props,'Header.preset'),[props.config]);

  return <StyledHeaderMobile config={config} isOpen={isOpen} onClick={() => toggleIsOpen()}>
    <HamIcon size={21} className={isOpen ? 'open' : ''} color={config?.themeLayout?.link} />
    <HeaderDropMenu isOpen={isOpen} menuItems={data?.links} data={data} config={config}/>
  </StyledHeaderMobile>
}

export default HeaderMobile;

/* style */

const StyledHeaderMobile = styled.div`
  overflow: hidden;
  height: 100%;
  padding: 0 10px;
  color: ${props => props.config?.layout?.HEADER_COLOR || props.config?.layout?.MAIN_COLOR};
  cursor: pointer;
  user-select: none;
  background: ${props => props.isOpen ? 'rgba(0,0,0,0.1)' : 'none'};
  
  @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    display:none;
  }
`