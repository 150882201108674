import { collection, getDoc, getDocs, doc, query, where, onSnapshot, addDoc, setDoc, serverTimestamp, orderBy, limit, startAfter } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider, signInWithPopup, signOut, sendPasswordResetEmail, fetchSignInMethodsForEmail, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import moment from 'moment';

import GameficationHelper from './GameficationHelper';
import { POINT_ACTIONS } from './consts';
import { HEROKU_AUTHORIZATION, HEROKU_LOYALTY_URL } from './URLconsts';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export default class UserHelper {
  constructor(db){
    this.db = db;
  }

  setDataHelper(dataHelper) {
    this.dataHelper = dataHelper;
  }
 
  async emailLogin(email, password) {
    const auth = getAuth();

    try{
      let userCredential = await signInWithEmailAndPassword(auth, email, password);
      let user = userCredential.user;
      let hasUserDoc = await this.firestoreEmailExists(user.email);

      return {
        success: true,
        user: userCredential,
        hasUserDoc,
      };
    }
    catch(error){
      return {
        success: false,
        error,
      }
    }
  }

  async getUserFirebaseId() {
    const auth = getAuth();
    let user = auth.currentUser;

    if (!user)
      return null;

    if (!global.userFirebaseId) {
      const userEmail = user?.email || user?.providerData[0]?.email || null;
      
      if (!userEmail) return null;
      let userQ = query(collection(this.db, 'Users'), where('Email', '==', userEmail));
      let userDocs = await getDocs(userQ)
      if (userDocs.size > 0) {
        userDocs.forEach((doc) => {
          global.userFirebaseId = doc.data().FirebaseId__c;
        })
        return global.userFirebaseId;
      } else {
        return null;
      }
    } else {
      return global.userFirebaseId;
    }
  }

  async getUserRef() {
    let userFirebaseId = await this.getUserFirebaseId();
    if (!userFirebaseId) return null;

    return doc(this.db,'Users',userFirebaseId);
  }

  async getUser(){
    let userRef = await this.getUserRef();

    if(userRef){
      let userDoc = await getDoc(userRef);

      if(userDoc.exists())
        return userDoc.data();
    }

    return null;
  }

  async snapUser(callback) {
    let userRef = await this.getUserRef();

    let unsub = onSnapshot(userRef, (snapshot) => {
      callback(snapshot.data());
    })

    let t = {};
    t['user' + new Date().getTime()] = unsub;

    return t;
  }

  getMessagingToken(callback){
    const messaging = getMessaging();
    
    getToken(messaging,{vapidKey: 'BPI04YAGQP8kN5SmKkwdcRWAz44rQryWWQZGB4dBMikKMhgVYCIj1iqHMvLo-9JuvBZFvZjXry8PsbqaCMDHoRU'}).then((currentToken) => {
      callback({currentToken});
    }).catch((err) => {
      callback({err});
    });
  }

  async authEmailExists(email){
    const auth = getAuth();
    const result = await fetchSignInMethodsForEmail(auth,email)

    return result.length > 0;
  }

  async firestoreEmailExists(email){
    let userQ = query(collection(this.db, 'Users'), where('Email', '==', email));
    let userDocs = await getDocs(userQ)
    return userDocs.size > 0;
  }

  async cpfExists(cpf){
    let cleanCpf = cpf.replace(/\D/g, '');

    let user = await this.getUser();
    if(user?.CNPJ_CPF__c && user.CNPJ_CPF__c === cleanCpf){
      return false;
    }

    let cpfQ = doc(this.db,'CPF',cleanCpf);
    let cpfDoc = await getDoc(cpfQ);

    return cpfDoc.exists();
  }

  handlePromoCode = async (promoCode) => {
    let promoCodeQ = query(collection(this.db, 'Users'), where('ReferralCode__c','==',promoCode));
    let promoCodeDocs = await getDocs(promoCodeQ)
    if(promoCodeDocs.size > 0){
      this.redeemGenerator = promoCodeDocs[0].data();
      return true;
    }

    return false;
  }

  editUserData = async (userData,userCredential) => {

    let userUID;
    if(userData?.UID){
      userUID = userData.UID;
    }
    else if(userCredential?.user?.uid){
      userUID = userCredential.user.uid;
    }
    else{
      return {
        success: false,
        error: {
          code: 'no-uid',
        }
      }
    }

    const Topics__c = this.subscribeAllTopics();

    let userId = await this.getUserFirebaseId();

    let firebaseIdDoc;
    let firebaseIdGetDoc;

    if(userId){
      firebaseIdDoc = doc(this.db,'UserFirebaseId',userId);
      firebaseIdGetDoc = await getDoc(firebaseIdDoc);
    }

    if(!userId || !firebaseIdGetDoc.exists()){
      // Criando UserFirebaseId
      firebaseIdDoc = await addDoc(collection(this.db, 'UserFirebaseId'),{
        Email: userData.Email,
      })
    }

    // Atualizando doc CPF
    if(userData.CNPJ_CPF__c){
      let cleanCpf = userData.CNPJ_CPF__c.replace(/\D/g, '');
      let cpfQ = doc(this.db,'CPF',cleanCpf);
  
      await setDoc(cpfQ,{
        Email:          userData.Email,
        CPF:            userData.CNPJ_CPF__c,
        UserFirebaseId: firebaseIdDoc.id,
      })
    }

    // Atualizando doc UserFirebase
    await setDoc(firebaseIdDoc,{
      UID: userUID,
      UserFirebaseId: firebaseIdDoc.id
    },{
      merge: true
    })

    // Atualizando doc User
    userData.UID = userUID;
    userData.FirebaseId__c = firebaseIdDoc.id;
    userData.MilestoneCategory__c = 'Parceiro';

    if(this.redeemGenerator){
      //Create Contact Action for the user that shared the code
      await GameficationHelper.addRow(this,POINT_ACTIONS.REFER_SENDER,null,null,null,null,null,null,null,null,null,null,this.redeemGenerator)
      //Create Contact Action for the actual user
      await GameficationHelper.addRow(this,POINT_ACTIONS.REFER_RECEIVER)
    }

    await this.setUserData(userData)

    // Criando registros de loyalty
    try {
      await this.updateLoyalty();
    } catch(e) {
    }
    
    return {
      success: true,
      user: userData,
    }
  }

  saveUser = async (userData) => {
    let requiredFields = ['Email'];
    let missingRequiredFields = requiredFields.slice();

    let aUserData = Object.entries(userData);

    for(let i=0;i<aUserData.length;i++){
      let index = missingRequiredFields.indexOf(aUserData[i][0]);
      if(index !== -1){
        missingRequiredFields.splice(index,1);
      }
    }
    
    if(missingRequiredFields.length > 0){
      return {
        success: false,
      };
    }

    userData.Password = userData.Password || Math.random().toString();

    const auth = getAuth();

    let user = await this.getUser();
    if(user?.UID){
      userData.UID = user.UID;
    }

    if(!userData.UID){
      try{
        let userCredential = await createUserWithEmailAndPassword(auth,userData.Email,userData.Password);
        return this.editUserData(userData,userCredential);
      }
      catch(error){
        return {
          success: false,
          error,
        };
      }
    }
    else{
      return this.editUserData(userData)
    }
  }

  setUserData = async (userData) => {
    // let { userFirebaseId, Email, Password, UID, FirstName, LastName, birthdate, phone, userDoc, mailingStreet, mailingCity, mailingState, mailingCountry, mailingZipCode, cpf, loyaltyCategory, milestoneCategory, emailOptin, profissao, areaAtuacao, lojaRelacionamento } = userData;

    let userDoc = {...userData};

    delete userDoc.Password;

    userDoc.LoyaltyEmail__c = userDoc.Email;

    if (userDoc.LoyaltyBirthdate__c !== null)
      userDoc.LoyaltyBirthdate__c = moment(userDoc.LoyaltyBirthdate__c, 'DD/MM/YYYY').format('YYYY-MM-DD');

    if (userDoc.CNPJ_CPF__c !== null) 
      userDoc.CNPJ_CPF__c = userDoc.CNPJ_CPF__c.replace(/\D/g, "");

    if(userData.FirebaseId__c)
      global.userFirebaseId = userData.FirebaseId__c;

    const ref = await this.getUserRef();

    const docResponse = setDoc(ref,{
      ...userDoc,
      lastSignInTime: serverTimestamp()
    },{merge: true});

    return docResponse;
  }

  subscribeAllTopics = async () => {
/*
    TODO - integrar isso a buscar o token
    var notificationsTopics = await this.getTopics();
    var topics = '';

    for(i = 0; i < notificationsTopics.length; i++) {
      docData = notificationsTopics[i];
      topics += docData.FirebaseId__c + ';';
      getMessaging().subscribeToTopic(token, docData.FirebaseId__c);
    }

    return topics
*/
  }

  getTopics = async () => {
    let topicsQ = query(collection(this.db, 'NotificationTopic'), orderBy('Order__c'));
    let topicsDocs = await getDocs(topicsQ);

    let topics = [];
    if(topicsDocs.size > 0){
      topicsDocs.forEach((doc) => {
        let docData = doc.data();

        if (docData.FirebaseId__c !== null && docData.FirebaseId__c !== '')
          topics.push(docData)
      })
    }

    return topics;
  }

  snapUserPoints = async (callback) => {
    let userDocRef = await this.getUserRef();
    let pointsQ = query(collection(userDocRef,'PointStatement'), orderBy('PointDate__c','desc'));

    let unsub = onSnapshot(pointsQ,(snapshot) => {
      let points = this.dataHelper.getList(snapshot);
      callback(points);
    })

    return {points:unsub}
  }

  getUserPoints = async (qty = 10,startAfterDoc = null) => {
    let userDocRef = await this.getUserRef();
    let pointsQ = query(collection(userDocRef,'PointStatement'),orderBy('PointDate__c','desc'),limit(qty));
    if(startAfterDoc){
      pointsQ = query(pointsQ,startAfter(startAfterDoc));
    }
    let pointsDoc = await getDocs(pointsQ);

    let data = [];
    let lastDoc = null;

    if(pointsDoc.docs.length){
      data = pointsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data , lastDoc };
  }

  googleLogin = (callback) => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    provider.addScope('email');

    signInWithPopup(auth, provider)
      .then(async (result) => {

        const credential = GoogleAuthProvider.credentialFromResult(result);

        const token = credential.accessToken;

        const user = result.user;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        const isNewUser = ( !email || !(await this.firestoreEmailExists(email)) );

        callback({
          success: true,
          isNewUser,
          user,
        })
      }).catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error);

        callback({
          success: false,
          error,
        })
      })
  }

  facebookLogin = (callback) => {
    const provider = new FacebookAuthProvider();
    
    provider.addScope('email');
    provider.setCustomParameters({
      display:'popup'
    })

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {

        const user = result.user;

        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        const isNewUser = ( !email || !(await this.firestoreEmailExists(email)) );

        callback({
          success: true,
          isNewUser,
          user,
        })
      })
      .catch((error) => {
        const credential = FacebookAuthProvider.credentialFromError(error);

        callback({
          success: false,
          error,
        })
      });
  }

  sendPasswordResetEmail = async (email) => {
    const auth = getAuth();

    try{
      await sendPasswordResetEmail(auth,email)

      return {
        success: true,
      }
    }
    catch(error){
      return {
        success: false,
        error
      }
    }
  }

  changePassword = async (userData) => {
    const auth = getAuth();
    let user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email,userData.currentPassword);
    try{
      let reauthResult = await reauthenticateWithCredential(user,credential);

      await updatePassword(user,userData.newPassword);

      return {
        success: true
      };
    }
    catch(error){
      return {
        success: false,
        error
      }
    }
  }

  logout = (successCallback,errorCallback) => {
    const auth = getAuth();
  
    signOut(auth).then(() => {
      global.userFirebaseId = null;

      successCallback();
    }).catch((error) => {
      errorCallback(error);
    });
  }

  updateLoyalty = async () => {
    let userDocRef = await this.getUserRef();
    let userDoc = await getDoc(userDocRef);

    if(userDoc.exists()){
      let userData = userDoc.data();
      await this.createLoyalty(userData);
    }
  }

  createLoyalty = async (userData) => {
    try {
      let userDocRef = await this.getUserRef();
      setDoc(userDocRef,{ lastCreateLoyaltyTime: serverTimestamp() }, { merge: true })
    
      if (!userData.LoyaltyEmail__c || !userData.LastName) {
        return;
      }

      var sfUserData = {
        firstName: userData.FirstName,
        lastName: userData.LastName,
        phone: userData.LoyaltyPhone__c,
        birthdate: userData.LoyaltyBirthdate__c,
        street: userData.LoyaltyStreet__c,
        city: userData.LoyaltyCity__c,
        state: userData.LoyaltyState__c,
        country: userData.LoyaltyCountry__c,
        postalCode: userData.LoyaltyPostalCode__c,
        email: userData.LoyaltyEmail__c ? userData.LoyaltyEmail__c.toLowerCase() : '',
        facebookId: userData.FacebookId__c,
        googleId: userData.GoogleId__c,
        twitterId: userData.TwitterId__c,
        firebaseId: userData.FirebaseId__c,
        messagingToken: userData.MessagingToken__c,
        topics: userData.Topics__c,
        cpf: userData.CNPJ_CPF__c,
        profissao: userData.Profissao__c,
        areaAtuacao: userData.AreaAtuacao__c,
        lojaRelacionamento: userData.LojaRelacionamento__c,
        // loyaltyMobileOS:            Platform.OS,
        // loyaltyMobileOSVersion:     Platform.Version,
        // loyaltyMobileBundleVersion: APP_VERSION,
      }

      var loyaltyUrl = await this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL);
      var response = await fetch(loyaltyUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
          'Host': 'souclamper-dev-sync.herokuapp.com',
        },
        body: JSON.stringify(sfUserData),
      });

      console.warn('#createLoyaltyResponse: ', response);

    } catch (error) {
      console.warn('createLoyaltyException', error);
    }
  }

  setLastTimeOnline = async () => {
    const userData = await this.getUser();

    if (!userData)
      return null;

    const dateRef = moment().format('YYYY-MM-DD');
    const lastOnline = userData.lastTimeOnline ? moment(userData.lastTimeOnline.toDate()).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

    const lastTimeNull = userData.lastTimeOnline == null;
    const isNew = dateRef > lastOnline;

    if (lastTimeNull || isNew) {
      await GameficationHelper.addRow(this,POINT_ACTIONS.DAILY_LOGIN);
    } 
    
    let userDocRef = await this.getUserRef();
    
    if(userDocRef)
      setDoc(userDocRef,{ lastTimeOnline: serverTimestamp() },{ merge: true });
  }

  uploadAvatar = async (img) => {
    const userFirebaseId = await this.getUserFirebaseId();
    const storage = getStorage();
    const storageRef = ref(storage, `ProfilePictures/${userFirebaseId}.jpeg`);
    const metadata = {contentType: 'image/jpeg'};

    uploadBytes(storageRef, img, metadata).then((snapshot) => {
      getDownloadURL(storageRef).then(async (url) => {
        const ref = await this.getUserRef();

        const docResponse = setDoc(ref,{
          photoURL: url,
        },{merge: true});
    
        return url;
      })
    });
  }

  setDocumentViewed = async (documentId) => {
    let userId = await this.getUserFirebaseId();

    if(documentId && userId){
      let viewRef = doc(this.db, 'Users', userId, 'View', documentId);
      let viewDoc = await getDoc(viewRef);

      if(!viewDoc.exists()){
        await GameficationHelper.addRow(this,POINT_ACTIONS.EVENT_CLICK_NEWS, documentId);
      }

      let viewData = {
        userId,
        lastViewDate: serverTimestamp(),
        documentId
      }

      setDoc(viewRef,viewData,{merge:true});
    }
  }

  setBannerViewed = async (bannerId) => {
    await GameficationHelper.addRow(this,POINT_ACTIONS.EVENT_CLICK_ADS, bannerId);

    return true;
  }

  getFinishedSurveyIds = async (isQuiz) => {
    const finishedQ = collection(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswers' : 'SurveyAnswers');
    const finishedDoc = await getDocs(finishedQ);
    const finishedSurveys = this.dataHelper.docsToArray(finishedDoc);

    let out = finishedSurveys.map((finishedSurvey) => finishedSurvey.Id);
    return out;
  }

  getSurveyQuestionAnswers = async (isQuiz) => {
    const answersQ = collection(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption');
    const answersDoc = await getDocs(answersQ);
    const answers = this.dataHelper.docsToArray(answersDoc);

    return answers;
  }

  surveySendAnswer = async (
    survey,
    question,
    selectedOptionIds, // array de strings
    textAnswer,
  ) => {
    
    let cgny2__Action__c = POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER + question.RecordType.DeveloperName.toUpperCase();
    let rightAnswer = false;
    const isQuiz = survey.FirebasePath__c === 'Quiz';

    if(typeof selectedOptionIds === 'object' && selectedOptionIds.length > 0){
      for (let i = 0; i < selectedOptionIds.length; i++) {
        const optId = selectedOptionIds[i];

        if (isQuiz) {
          if (optId === question.RightAnswer__c) {
            rightAnswer = true;
            cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_RIGHT_ANSWER;
          }
          else {
            cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_WRONG_ANSWER;
          }
        }

        await GameficationHelper.addRow(
          this,
          cgny2__Action__c,
          question.Id, //cgny2__GenericId__c,
          undefined, //cgny2__Event__c,
          textAnswer, //cgny2__Value__c,
          undefined, //cgny2__AdvertisingItem__c,
          undefined, //cgny2__Gallery__c,
          undefined, //cgny2__News__c,
          question.Survey__c, //cgny2__Survey__c,
          optId, //cgny2__SurveyQuestionOption__c,
          question.Id, //cgny2__SurveyQuestion__c,
          undefined //cgny2__Voucher__c
        );
      }
    }
    else {
      // Uma resposta pode ter apenas Answer e não ter opções selecionadas
      await GameficationHelper.addRow(
        this,
        cgny2__Action__c,
        question.Id, //cgny2__GenericId__c,
        undefined, //cgny2__Event__c,
        textAnswer, //cgny2__Value__c,
        undefined, //cgny2__AdvertisingItem__c,
        undefined, //cgny2__Gallery__c,
        undefined, //cgny2__News__c,
        question.Survey__c, //cgny2__Survey__c,
        undefined, //cgny2__SurveyQuestionOption__c,
        question.Id, //cgny2__SurveyQuestion__c,
        undefined //cgny2__Voucher__c
      );
    }

    let tmpQuestionAnswer = {
      Id: question.Id,
      question,
      rightAnswer,
      actionDate: serverTimestamp(),
    };

    if(survey.Status__c !== 'Test'){
      let questionDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption', question.Id);
      setDoc(questionDoc,tmpQuestionAnswer);
      //   this.props.surveyQuestionAnswersRef.doc(question.Id).set(tmpQuestionAnswer);

    }

    // this.props.addSurveyQuestionAnswersTest(tmpQuestionAnswer);

    // if (!this.props.surveyStatusTest) {
    // }

    // if (isQuiz) {
    //   if (rightAnswer) {
        // DropDownHolder.getDropDown().alertWithType('success', global.labels.QUIZ_TITLE_RIGHT, global.labels.QUIZ_RIGHT_ANSWER);
      // }
      // else {
        // DropDownHolder.getDropDown().alertWithType('error', global.labels.QUIZ_TITLE_ALERT, global.labels.QUIZ_WRONG_ANSWER);
      // }
    // }
  }

  surveySendClosure = async (survey) => {
    const isQuiz = survey.FirebasePath__c === 'Quiz';
    let surveyDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswers' : 'SurveyAnswers', survey.Id);

    setDoc(surveyDoc,{
      Id: survey.Id,
      actionDate: serverTimestamp()
    })

    const cgny2__Action__c = isQuiz ? POINT_ACTIONS.ACTION_QUIZ_COMPLETE : POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE;
    const cgny2__GenericId__c = survey.Id;

    GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      cgny2__GenericId__c,
      undefined, //cgny2__Event__c,
      undefined, //cgny2__Value__c,
      undefined, //cgny2__AdvertisingItem__c,
      undefined, //cgny2__Gallery__c,
      undefined, //cgny2__News__c,
      survey.Id, //cgny2__Survey__c,
      undefined, //cgny2__SurveyQuestionOption__c,
      undefined, //cgny2__SurveyQuestion__c,
      undefined //cgny2__Voucher__c
    );
  }
}