const THEME = {
  BannerSection: {
    preset: 'default',
  },
  ChallengeItem: {
    preset: 'box',
  },
  ChallengeSection: {
    preset: 'default',
  },
  Header: {
    preset: 'header',
  },
  HeaderDropMenu: {
    preset: 'box',
  },
  HowToEarnSection: {
    preset: 'default',
  },
  HowToEarnItem: {
    preset: 'box',
  },
  LoginBox: {
    preset: 'default',
  },
  RewardListSection: {
    preset: 'default',
  },
  RewardItem: {
    preset: 'box',
    presetPopup: 'box',
  },
  NewsSection: {
    preset: 'default',
  },
  NewsListSection: {
    presetTabs: 'box',
    preset: 'default',
  },
  ArticleBox: {
    preset: 'box',
  },
  ArticleBoxBig: {
    preset: 'default',
  },
  ArticleTextSection: {
    preset: 'default',
  },
  GameSection: {
    preset: 'colorful',
    presetBox: 'box',
    presetRewardPopup: 'default',
  },
  PointListSection: {
    preset: 'funkyDefault',
  },
  PointLine: {
    preset: 'default',
  },
  ProductList: {
    preset: 'default',
    presetHeader: 'listHeader',
    presetBox: 'box',
    presetBanner: 'listBanner',
  },
  ProductPopup: {
    preset: 'default',
  },
  ListBox: {
    preset: 'default',
  },
  Filter: {
    preset: 'colorful',
  },
  SurveyListSection: {
    preset: 'default',
    presetTabs: 'box',
    presetBox: 'box',
  },
  SurveyRespondSection: {
    preset: 'funkyDefault',
    presetBox: 'box',
  }
}

export default THEME;