import { useState, useRef, useEffect } from 'react';

const FormHelper = {
  useFormInput: (data) => {

    const ref = useRef(null);

    const [ error, setError ] = useState('');
   
    const getValue = () => ref?.current?.value || '';

    const setValue = (value) => {
      console.log(data.id + ' // setting value: ['+value+']');
      console.log(ref?.current);

      if(value !== undefined && ref?.current){
        console.log('ref.current is defined!')
        ref.current.value = value;
        ref.current.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }

    const setFocus = () => ref?.current?.focus();

    const focusError = (message) => {
      setError(message);
      setFocus();
    }
/*
    useEffect(() => {
      if(data?.value){
        setValue(data.value)
      }
    },[])
*/  
    return {
      ...data,

      ref,
      error,

      setValue,
      getValue,
      setError,
      setFocus,
      focusError,
    }
  },
  validateEmail: (email) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  },
  validateCPF: (cpf) => {
    if (typeof cpf !== 'string') return false
    
    cpf = cpf.replace(/[^\d]+/g, '')
    
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    
    cpf = cpf.split('')
    
    const validator = cpf
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        .map( el => +el )
        
    const toValidate = pop => cpf
        .filter((digit, index, array) => index < array.length - pop && digit)
        .map(el => +el)
    
    const rest = (count, pop) => (toValidate(pop)
        .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) 
        % 11 
        % 10
        
    return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
  },
  validateAge: ({date,minAge,maxAge}) => {
    if(!date){
      return !minAge && !maxAge;
    }

    if(date.search('-') === -1)
      date = date.split('/').reverse().join('-');

    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return (minAge === undefined || minAge < age) && (maxAge === undefined || age < maxAge)
  }
}

export default FormHelper;