import { useState } from "react";

const ToolHelper = {
  usePopup: () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ content, setContent ] = useState('');
    const [ themeLayout, setThemeLayout ] = useState({});
  
    return {
      isOpen,
      setIsOpen,
      content,
      setContent,
      themeLayout,
      setThemeLayout,
    }
  },
  // deprecated -- usar getThemedConfig
  getTheme: (props,customPreset,defaultPreset = 'default') => {
    let preset = customPreset;
    let theme = props.config.presets[preset];
    let defaultTheme = props.config.presets[defaultPreset];

    theme = {...defaultTheme, ...theme, ...props.theme};

    return theme;
  },
  getThemedConfig: (props,presetPath,defaultPreset = 'default') => {
    let themeLayout = {};
    if(presetPath){
      let aPresetPath = presetPath.split('.');
      let calculatedPreset = props.config.theme;
      aPresetPath.map((path) => {
        calculatedPreset = calculatedPreset[path] || {}
      });
      themeLayout = props.config.presets[calculatedPreset];
    }

    let defaultTheme = props.config.presets[defaultPreset];
    themeLayout = {...defaultTheme, ...themeLayout, ...props.theme};

    return {...props.config, themeLayout};
  },
  // Faz as substituições padrões de marcadores e possíveis extras
  fillTextPlaceholder: (text,config,extra = []) => {
    if(typeof text !== 'string'){
      return '';
    }

    // Trocas padrões
    extra.push({find:'{POINTS_LABEL}',replace:config?.strings?.POINTS});
    extra.push({find:'{LEVEL_LABEL}',replace:config?.strings?.LEVEL});

    let result = text;

    extra.forEach((sub) => {
      if(result.includes(sub.find)){
        // Se a string possui o placeholder mas não tem a troca definida, a string será apagada
        if(sub.replace === undefined || sub.replace === false){
          result = '';
        }
        else{
          result = result.replaceAll(sub.find,sub.replace)
        }
      }
    })

    return result;
  },
  // Remove tags HTML de uma string
  stripTags: (html) => {
    var tmp = document.createElement('div');
    tmp.innerHTML = html;
    
    return tmp.textContent || tmp.innerText;
  },
  // Trunca uma string e adiciona elipse, caso necessário
  truncate: (string,size) => {
    return (string.length > size) ? string.substr(0, size-1) + '&hellip;' : string;
  },
  // Inclui um JS no corpo do HTML
  includeJS: (path,callback) => {
    const script = document.createElement("script");
    if(callback){
      script.onload = callback;
    }
    script.src = path;
    script.async = true;
    document.body.appendChild(script);
  },
  // Inclui um CSS no corpo do HTML
  includeCSS: (path) => {
    const css = document.createElement("link");
    css.rel = 'stylesheet';
    css.type = 'text/css';
    css.href = path;
    document.body.appendChild(css);
  },
  // Avalia uma variável de tamanho e insere "px" quando necessário para simular o comportamento do react CSS
  fixSizeUnit: (size) => {
    return typeof size === 'number' ? size + 'px' : size;
  }
}

export default ToolHelper;