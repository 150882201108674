import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={
        ({ location }) => {
          return (
            global.isAuthenticated
            ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
          ))
        }
      }
    />
  );
}

export default PrivateRoute;