import styled from '@emotion/styled';

/*
  props
    color
    size
*/

const HamLine = styled.span(props => ({
  display: 'block',
  position: 'absolute',
  height: props.size * 0.2,
  width: '100%',
  background: props.color || '#333',
  borderRadius: props.size * 0.2,
  opacity: '1',
  left: '0',
  transform: 'rotate(0deg)',
  transition: '.25s ease-in-out',
  '&:nth-of-type(1)':{
    top: 0,
    transformOrigin:'left center',
  },
  '&:nth-of-type(2)':{
    top: props.size * 0.4,
    transformOrigin:'left center',
  },
  '&:nth-of-type(3)':{
    top: props.size * 0.8,
    transformOrigin:'left center',
  },
}));

const HamContainer = styled.div(props => ({
  width: props.size,
  height: '100%',
  alignSelf: 'center',
  alignItems: 'center',
  position: 'relative',
  // margin: '50px auto',
  transform: 'rotate(0deg)',
  transition: '.5s ease-in-out',
  cursor: 'pointer',
  '&.open':{
    'span:nth-of-type(1)':{
      transform: 'rotate(45deg)',
      top: 0.066 * props.size,
      left: 0.15 * props.size,
    },
    'span:nth-of-type(2)':{
      width: '0%',
      opacity: 0,
    },
    'span:nth-of-type(3)':{
      transform: 'rotate(-45deg)',
      top: props.size * 0.766,
      left: props.size * 0.15,
    },
  },
}))

function HamIcon(props){
  let { size, color, className } = props;
  size = size || 45;

  return <HamContainer size={size} className={className}>
    <div style={{marginTop:'100%'}}>
      <HamLine size={size} color={color}/>
      <HamLine size={size} color={color}/>
      <HamLine size={size} color={color}/>
    </div>
  </HamContainer>
}

export default HamIcon;